const videoGalleries = Array.from(document.querySelectorAll('[data-video-gallery]'))
const players = {}

export default () => {
    if (videoGalleries.length === 0) return

    const galleryItems = Array.from(document.querySelectorAll('[data-video-link]'))
    const youTubeVideosExist = galleryItems.some(item => item.dataset.videoType === 'youtube')
    const vimeoVideosExist = galleryItems.some(item => item.dataset.videoType === 'vimeo')
    if (youTubeVideosExist) importLibrary('https://www.youtube.com/iframe_api')
    if (vimeoVideosExist) importLibrary('https://player.vimeo.com/api/player.js')

    window.onYouTubeIframeAPIReady = () => loadedLibrary('youtube')
    galleryItems.forEach(makeSelectable)
}

function makeSelectable(item) {
    item.addEventListener('click', () => {
        if (item.classList.contains('bg-navy-200')) return
        const galleryId = item.dataset.videoGalleryId
        const videoEmbedCode = item.dataset.videoEmbedCode
        const videoType = item.dataset.videoType
        const videoPlayerTitle = item.closest('[data-video-gallery]').querySelector('[data-video-player-title]')
        const videoTitle = item.querySelector('[data-video-link-title]').innerText
        videoPlayerTitle.innerText = videoTitle
        item.closest('[data-video-gallery]')
            .querySelectorAll('[data-video-link]')
            .forEach(item => item.classList.remove('bg-navy-200'))
        item.classList.add('bg-navy-200')
        const container = item.closest('[data-video-gallery]').querySelector('[video-container]')
        if (players[galleryId].loadVideoById && videoType === 'youtube')
        return players[galleryId].loadVideoById(videoEmbedCode).playVideo()
        if (players[galleryId].loadVideoById && videoType === 'vimeo') {
            container.innerHTML = `<div data-video-player data-autoplay></div>`
            return vimeoPlayer(galleryId, container.firstChild, videoEmbedCode)
        }
        if (players[galleryId].loadVideo && videoType === 'vimeo')
        players[galleryId].loadVideo(videoEmbedCode).then(() => players[galleryId].play())
        if (players[galleryId].loadVideo && videoType === 'youtube') {
            container.innerHTML = `<div data-video-player data-autoplay></div>`
            return youtubePlayer(galleryId, container.firstChild, videoEmbedCode)
        }
    })
}

function loadedLibrary(videoType) {
    videoGalleries.filter(gallery => {
        const firstItem = gallery.querySelector('[data-video-link]')
        return firstItem.dataset.videoType === videoType
    })
    .forEach(setupPlayer)
}

function setupPlayer(gallery) {
    const galleryId = gallery.dataset.videoGallery
    const playerEl = gallery.querySelector('[data-video-player]')
    const items = gallery.querySelectorAll('[data-video-link]')
    const firstItem = items[0]
    if (!firstItem) return
    const videoType = firstItem.dataset.videoType
    const videoEmbedCode = playerEl.dataset.videoEmbedCode
    if (videoType === 'youtube') return youtubePlayer(galleryId, playerEl, videoEmbedCode)
    if (videoType === 'vimeo') return vimeoPlayer(galleryId, playerEl, videoEmbedCode)
}

function youtubePlayer(galleryId, playerEl, videoEmbedCode) {
    const autoplay = playerEl.dataset.autoplay === '' ? true : false
    players[galleryId] = new YT.Player(playerEl, {
        height: '390',
        width: '640',
        videoId: videoEmbedCode,
        playerVars: {
            autoplay,
            'modestbranding': 0,
            'playsinline': 1,
            'origin': location.origin
        }
    })
}

function vimeoPlayer(galleryId, playerEl, videoEmbedCode) {
    const autoplay = playerEl.dataset.autoplay === '' ? true : false
    players[galleryId] = new Vimeo.Player(playerEl, {
        id: videoEmbedCode,
        autoplay,
        height: '390',
        width: '640'
    })
}

function importLibrary(src) {
    const tag = document.createElement('script')
    tag.src = src
    tag.async = true
    if (src.includes('vimeo')) tag.onload = () => loadedLibrary('vimeo')

    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
}