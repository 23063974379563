import Swiper, { Autoplay, Navigation, Pagination, Thumbs, EffectFade } from 'swiper';
import ScrollOut from "scroll-out";
import initVideoGalleries from './video-gallery'

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {


    // Carousel block (Swiper)
    Swiper.use([Autoplay, Navigation, Pagination, Thumbs, EffectFade]);

    const swipers = [];
    document.querySelectorAll('.carousel .swiper-container').forEach(function(el) {
        const id = el.getAttribute('id');
        const selector = id ? '#' + id : '';

        const swiper = new Swiper('.carousel .swiper-container'+selector, {
            loop: false,
            slidesPerView: 'auto',
            spaceBetween: 0,
            navigation: {
                nextEl: '.carousel__next',
                prevEl: '.carousel__prev',
            }
        });
        swipers.push(swiper);
    });

    document.querySelectorAll('.gallery .swiper-container').forEach(function(el) {
        const id = el.getAttribute('id');
        const selector = id ? '#' + id : '';

        const swiper = new Swiper('.gallery .swiper-container' + selector, {
            spaceBetween: 0,
            navigation: {
                nextEl: '.gallery__next',
                prevEl: '.gallery__prev',
            }
        });
        swipers.push(swiper);
    });


    /*******/ // Gallery with thumbnails /*******/

    document.querySelectorAll('.gallery-thumbnails .swiper-container').forEach(function(el) {
        // get ID of the gallery
        const galleryId = el.getAttribute('id');
        const galleryIdSelector = galleryId ? ('#' + galleryId) : '';
        // remove the substring 'Thumbs' from the ID
        const galleryIdNoThumbs = galleryId?.replace('Thumbs', '');
        const galleryIdNoThumbsSelector = galleryIdNoThumbs ? ('#' + galleryIdNoThumbs + 'Gallery') : '';

        const thumbsSelector = '.gallery-thumbnails .swiper-container'+galleryIdSelector;

        if (!document.querySelector(thumbsSelector)) {
            return;
        }

        const thumbs = new Swiper(document.querySelector('.gallery-thumbnails .swiper-container'+galleryIdSelector), {
            slidesPerView: 'auto',
            spaceBetween: 0,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
        });

        console.log('thumbs', thumbs)
        const thumbsWrapperSelector = '.gallery-with-thumbnails' + galleryIdNoThumbsSelector;
        var thumbWrapper = document.querySelector(thumbsWrapperSelector);

        const galleryThumbsSelector = '.gallery-with-thumbnails' + galleryIdNoThumbsSelector + ' .swiper-container';
        const galleryThumbs = new Swiper(document.querySelector(galleryThumbsSelector), {
            spaceBetween: 0,
            navigation: {
                nextEl: '.gallery__next#'+galleryIdNoThumbs+'Next',
                prevEl: '.gallery__prev#'+galleryIdNoThumbs+'Prev',
            },
            thumbs: {
                swiper: thumbs,
            },
        });

        if(thumbWrapper && galleryThumbs){
            setTimeout(() => {
                galleryThumbs?.update()
            }, 500)
        }

        swipers.push(galleryThumbs);
    });

    /*********/

    // Mobile navigation
    var nav = document.querySelector('.mobile');
    var navTrigger = document.querySelector('.js-mobile-btn');
    var navPanel = document.querySelectorAll('.mobile-panel');
    var navPanelTriggers = document.querySelectorAll('.js-mobile-panel-btn');

    if(navTrigger){
        bindEvent(navTrigger, 'click', function() {
            event.preventDefault();
            navTrigger.classList.toggle('active');
            nav.classList.add('transition');
            nav.classList.toggle('active');
            document.body.classList.toggle('overflow-hidden');
        }, false);
    }

    if (navPanelTriggers) {
        for (let i = 0, l = navPanelTriggers.length; i < l; i += 1) {
            navPanelTriggers[i].addEventListener('click', (event) => {
                event.preventDefault();
                const panelId = event.currentTarget.dataset.panelId;
                const panel = document.getElementById(`mobile-panel-${panelId}`);
                panel.classList.toggle('active');
            });
        }
    }


    // Mega menu
    var panels = document.querySelectorAll('.panel');
    var panelTriggers = document.querySelectorAll('.js-panel-btn');
    var subPanels = document.querySelectorAll('.sub-panel');
    var subPanelTriggers = document.querySelectorAll('.js-sub-panel-btn');
    var overlay = document.querySelector('.overlay');


    // Mega menu top level
    if (panelTriggers) {
        for (let i = 0, l = panelTriggers.length; i < l; i += 1) {
            panelTriggers[i].addEventListener('click', (event) => {

                event.preventDefault();
                const panelId = event.currentTarget.dataset.panelId;
                const panel = document.getElementById(`panel-${panelId}`);
                const link = document.getElementById(`link-${panelId}`);
                const linkActive = link.classList.contains('active') ? true : false;

                if (linkActive) {
                    panel.classList.remove('active');
                    link.classList.remove('active');
                    overlay.classList.remove('active');
                    overlay.classList.remove('z-20');
                }
                else {
                    removeMenus();
                    panel.classList.add('active');
                    link.classList.add('active');
                    overlay.classList.add('active');
                    overlay.classList.add('z-20');
                }

            });
        }
    }

    var megaMenuItems = document.querySelectorAll('.mega-menu__item');
    var megaMenuLinks = document.querySelectorAll('.mega-menu__link');

    // Mega menu second level
    // if (subPanelTriggers) {
    //     for (let i = 0, l = subPanelTriggers.length; i < l; i += 1) {
    //         subPanelTriggers[i].addEventListener('click', (event) => {

    //             event.preventDefault();
    //             const subPanelId = event.currentTarget.dataset.subPanelId;
    //             const subPanel = document.getElementById(`sub-panel-${subPanelId}`);
    //             const subLink = document.getElementById(`sub-link-${subPanelId}`);

    //             const sublinkActive = subLink.classList.contains('active') ? true : false;

    //             if (sublinkActive) {
    //                 subPanel.classList.remove('active');
    //                 subLink.classList.remove('active');
    //             }
    //             else {
    //                 removeSubMenus();
    //                 subPanel.classList.add('active');
    //                 subLink.classList.add('active');;
    //             }

    //         });
    //     }
    // }

    // function removeSubMenus() {
    //     subPanelTriggers.forEach(item => {
    //         item.classList.remove('active');
    //     })
    //     subPanels.forEach(item => {
    //         item.classList.remove('active');
    //     })
    // }

    function removeMenus() {
        panels.forEach(item => {
            item.classList.remove('active');
        })
        panelTriggers.forEach(item => {
            item.classList.remove('active');
        })
        subPanels.forEach(item => {
            item.classList.remove('active');
        })
        subPanelTriggers.forEach(item => {
            item.classList.remove('active');
        })
    }

    function removeAll() {
        removeMenus();
        overlay.classList.remove('z-20');
        overlay.classList.remove('active');
        search.classList.remove('active');
        lang.classList.remove('active');

        if(videoOverlay) {
            videoOverlay.classList.remove('active');
        }

    }

    if(overlay) {
        overlay.addEventListener('click', function() {
            removeAll();
        })
    }


    // Search modal
    const searchTriggers = document.querySelectorAll('.js-search-btn');
    var searchCloseTrigger = document.querySelector('.js-search-close-btn');
    var search = document.querySelector('.search');
    var searchInput = document.querySelector('.search__input');

    if(searchTriggers) {
        for (let i = 0, l = searchTriggers.length; i < l; i += 1) {
            searchTriggers[i].addEventListener('click', (e) => {
                e.preventDefault();
                removeAll();
                search.classList.toggle('active');
                overlay.classList.toggle('active');
                searchInput.focus();
            });
        }
    }

    if(searchCloseTrigger) {
        searchCloseTrigger.addEventListener('click', function() {
            removeAll();
        })
    }

    // Video modal
    var videoTriggers = document.querySelectorAll('.js-video-btn');
    var videoCloseTrigger = document.querySelector('.js-video-close-btn');
    var videoOverlay = document.querySelector('.video-overlay');
    //var searchInput = document.querySelector('.search__input');

    if(videoTriggers) {
        for (let i = 0, l = videoTriggers.length; i < l; i += 1) {
            videoTriggers[i].addEventListener('click', (event) => {
                event.preventDefault();
                removeAll();
                videoOverlay.classList.toggle('active');
                overlay.classList.toggle('active');
               // videoInput.focus();


            });
        }
    }

    if(videoCloseTrigger) {
        videoCloseTrigger.addEventListener('click', function() {
            removeAll();
        })
    }


    // Language modal
    var langTriggers = document.querySelectorAll('.js-lang-btn');
    var langCloseTrigger = document.querySelector('.js-lang-close-btn');
    var lang = document.querySelector('.lang');

    if(langTriggers) {
        for (let i = 0, l = langTriggers.length; i < l; i += 1) {
            langTriggers[i].addEventListener('click', (event) => {
                event.preventDefault();
                removeAll();
                lang.classList.toggle('active');
                overlay.classList.toggle('active');
            });
        }
    }

    if(langCloseTrigger) {
        langCloseTrigger.addEventListener('click', function() {
            removeAll();
        })
    }

    // Show the pop up for first visitor only
    function GetCookie(name) {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) {
            return match[2];
        } else {
            return null;
        }
    }

    function findFirstTimeVisitor() {
        var visit = GetCookie("FirstTimeVisitCookie");
        if (visit == null) {
            var expire = new Date();
            expire = new Date(expire.getTime() + 7777600700);
            document.cookie = "FirstTimeVisitCookie="+ 1 +"; expires=" + expire + ";path=/";
            removeAll();
            lang.classList.toggle('active');
            overlay.classList.toggle('active');	// New visitor
        } else {
            removeAll();
            // potentially show message here that they have selected different store, etc
            // for stage 2
        }
    }

    if (lang != null) {
        findFirstTimeVisitor();
    }

    // Accordions
    // If there is an accordion inside an accordion then data-accordion-allow-multiple needs to be used
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = e.target.closest('[data-accordion-link]');
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }


    // Accordion tabs
    const tabSystem = {
        init() {
            document.querySelectorAll('[data-accordion-tabs]').forEach(tabMenu => {
                Array.from(tabMenu.querySelectorAll('[data-accordion-tabs-link]')).forEach((link) => {
                    link.addEventListener('click', () => {
                        tabSystem.toggle(link.dataset.target);
                    });
                    // if(link.className.includes('is-active')){
                    //     tabSystem.toggle(link.dataset.target);
                    // }
                });
            });
        },
        toggle(targetId) {
            document.querySelectorAll('[data-accordion-tabs-content]').forEach(content => {
                content.classList[content.id === targetId ? 'toggle' : 'remove']('is-active');
                document.querySelectorAll(`[data-target="${content.id}"]`).forEach((link) => {
                    link.classList[content.id === targetId ? 'toggle' : 'remove']('is-active');
                });
            });
        },
    };
    tabSystem.init();


    // Filters reveal
    var filtersTrigger = document.querySelector('.js-filters-btn');
    var filters = document.querySelector('.filters');

    if(filtersTrigger){
        bindEvent(filtersTrigger, 'click', function() {
            event.preventDefault();
            filters.classList.toggle('is-active');
            filtersTrigger.classList.toggle('is-active');
        }, false);
    }


    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener) {
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent) {
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

    ScrollOut({
        targets: '[data-scroll]',
        once: 'true'
    });


    // Video gallery
    initVideoGalleries()
})
